require("@rails/ujs").start();
require("trix");
require("trix/dist/trix");
require("@rails/actiontext");
import "bootstrap";
import "@rails/actiontext";

require.context("../assets/images", true);

import "../assets/styles/index.scss";
import "../assets/ts/alerts";

import "../assets/ts/billing";
import "../assets/ts/form-submission";
import "../assets/ts/assignments";
import "../assets/ts/student-name-search";
import "../assets/ts/admin-student-name-search";
import "../assets/ts/admin-event-details-toggle";
import "../assets/ts/school-name-search";
import "../assets/ts/judging-rubric-form";
import "../assets/ts/sidebar";
import "../assets/ts/school-activation";
import "../assets/ts/school-participation";
import "../assets/ts/judge-assignments";
import "../assets/ts/district-rubric-toggle-student";
import "../assets/ts/judges-parts";
import "../assets/ts/judge-submissions";
import "../assets/ts/judges-search";
import "../assets/ts/elementary-all-state-search.ts";
import "../assets/ts/admin-payments.ts";

import "../assets/ts/add-student/add-student-grade-select.ts";
import "../assets/ts/add-student/add-student-elementary-school-select.ts";
import "../assets/ts/add-student/add-student-dropdown";

import "../assets/ts/all-state/all-state-search";
import "../assets/ts/all-state/all-state-seating";
import "../assets/ts/all-state/all-state-eligible";

import "../assets/ts/auditions/audition-part-select";
import "../assets/ts/auditions/audition-name-search";

import "../assets/ts/compositions/composition-category-select";

import "../assets/ts/district-school-select";
import "../assets/ts/district-select";

import "../assets/ts/select-mailer";
import "../assets/ts/educator-search";

import "../assets/ts/festival-entries/festival-entry-select";
import "../assets/ts/festival-entries/festival-search";

import "../assets/ts/judge-approvals/judge-approval-form-submission";
import "../assets/ts/attended-festival";
import "../assets/ts/auditioned";

import "../assets/ts/pilot-event/pilot-event-part-select";

// React Rails
const componentRequireContext = require["context"]("components", true);
const ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
